/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location }) => {
  // if (location.pathname === "/patient") return
  if (location.hash) {
    const id = location.hash.substring(1)
    const element = document.getElementById(id)
    if (element) {
      setTimeout(function () {
        element.scrollIntoView()
      }, 10)
    }
  } else {
    window.scrollTo(0, 0)
  }
}
