exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hcp-404-js": () => import("./../../../src/pages/hcp/404.js" /* webpackChunkName: "component---src-pages-hcp-404-js" */),
  "component---src-pages-hcp-clinical-data-js": () => import("./../../../src/pages/hcp/clinical-data.js" /* webpackChunkName: "component---src-pages-hcp-clinical-data-js" */),
  "component---src-pages-hcp-hear-from-experts-js": () => import("./../../../src/pages/hcp/hear-from-experts.js" /* webpackChunkName: "component---src-pages-hcp-hear-from-experts-js" */),
  "component---src-pages-hcp-index-js": () => import("./../../../src/pages/hcp/index.js" /* webpackChunkName: "component---src-pages-hcp-index-js" */),
  "component---src-pages-hcp-mtx-use-in-patients-js": () => import("./../../../src/pages/hcp/mtx-use-in-patients.js" /* webpackChunkName: "component---src-pages-hcp-mtx-use-in-patients-js" */),
  "component---src-pages-hcp-pjia-discussion-guide-js": () => import("./../../../src/pages/hcp/pjia-discussion-guide.js" /* webpackChunkName: "component---src-pages-hcp-pjia-discussion-guide-js" */),
  "component---src-pages-hcp-reimbursement-js": () => import("./../../../src/pages/hcp/reimbursement.js" /* webpackChunkName: "component---src-pages-hcp-reimbursement-js" */),
  "component---src-pages-hcp-request-rep-js": () => import("./../../../src/pages/hcp/request-rep.js" /* webpackChunkName: "component---src-pages-hcp-request-rep-js" */),
  "component---src-pages-hcp-sitemap-js": () => import("./../../../src/pages/hcp/sitemap.js" /* webpackChunkName: "component---src-pages-hcp-sitemap-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-404-js": () => import("./../../../src/pages/patient/404.js" /* webpackChunkName: "component---src-pages-patient-404-js" */),
  "component---src-pages-patient-faqs-js": () => import("./../../../src/pages/patient/faqs.js" /* webpackChunkName: "component---src-pages-patient-faqs-js" */),
  "component---src-pages-patient-index-js": () => import("./../../../src/pages/patient/index.js" /* webpackChunkName: "component---src-pages-patient-index-js" */),
  "component---src-pages-patient-indications-js": () => import("./../../../src/pages/patient/indications.js" /* webpackChunkName: "component---src-pages-patient-indications-js" */),
  "component---src-pages-patient-sitemap-js": () => import("./../../../src/pages/patient/sitemap.js" /* webpackChunkName: "component---src-pages-patient-sitemap-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

